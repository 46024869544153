import React, { FC } from 'react';
import { StatsBar } from '../stats-bar/stats-bar';
import { RootState } from '../../types/store-types';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { getCounter } from '../../selectors/counters-selectors';
import { withDirection, DIRECTIONS } from '../../../common/react-with-direction';

type PostFooterStatsBarProps = {
  post?: any;
  category?: any;
  className?: string;
} & PostFooterStatsBarRuntimeProps;

type PostFooterStatsBarRuntimeProps = {
  isViewsCountEnabled?: boolean;
  getViewsCount: (postId: string) => number;
  direction?: any;
};

const PostFooterStatsBarComponent: FC<PostFooterStatsBarProps> = ({
  post,
  category,
  className,
  isViewsCountEnabled,
  getViewsCount,
  direction,
}) => {
  const isReactionsEnabled = category?.postInteraction !== 'none';
  const viewsCount = getViewsCount(post._id);
  const isRTL = direction === DIRECTIONS.RTL;

  return (
    <div className={className}>
      <StatsBar
        isRTL={isRTL}
        reactions={isReactionsEnabled ? post?.reactionCount : undefined}
        comments={post?.totalComments}
        views={isViewsCountEnabled ? viewsCount : undefined}
      />
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any, host: any) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  getViewsCount: (postId: string) => getCounter(state, postId)?.viewCount || 0,
});

export const PostFooterStatsBar = flowRight(
  connect(mapRuntimeToProps),
  withDirection,
)(PostFooterStatsBarComponent);
